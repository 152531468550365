import * as React from "react";
import { graphql } from "gatsby";
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CHistoryList,
  LAboutConceptSect,
  LAboutPromiseSect,
  CBannercard,
  CBtnList,
  NavAbout,
} from "../../components/_index";
import { size } from "lodash";
import { setLang } from "../../utils/set-lang";

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            ja: "ロイヤルパークホテルズについて",
          },
          img: {
            src: "/assets/images/about/kv.png",
          },
          imgSp: {
            src: "/assets/images/about/kv__sp.png",
          },
        }}
      />
      <CBreadCrumb
        data={{
          parent: [],
          current: {
            label: "ロイヤルパークホテルズについて",
          },
        }}
      />
      <LAboutConceptSect />
      <LAboutPromiseSect
        data={[
          {
            title: {
              src: "/assets/images/about/title_promise.svg",
              alt: "その街ならではのおもてなしを",
            },
            text: (
              <>
                街には、その地で暮らす人たちの輝き、そこにしかない歴史と文化があります。私たちは、その街の息吹を感じ、人に触れ、創意工夫を重ねることで、その街ならではのおもてなしをお客様にご提供いたします。
              </>
            ),
            titleEn: "TRULY LOCAL",
            textEn: (
              <>
                Royal Park Hotels truly blend into their communities and offer
                continuity with the city. No two properties are just alike each
                of them comes with curated architecture and experiences seeped
                in the character of the destination.
              </>
            ),
          },
          {
            title: {
              src: "/assets/images/about/title_promise02.svg",
              alt: "五感のすべてに心地よく",
            },
            text: (
              <>
                お客様に、一瞬一瞬を心地よくお過ごしいただくために。私たちは、五感に配慮し、巧みを凝らし、常にお客様のお気持ちをお察しすることで、お客様にとって心地よい空間をご提供いたします。
              </>
            ),
            titleEn: "COMFORT HAVEN",
            textEn: (
              <>
                Our places are filled with clever features thoughtfully
                appointed for absolute comfort. We pride ourselves on our
                uniquely warm atmosphere where guests feel that they belong and
                truly relax.
              </>
            ),
          },
          {
            title: {
              src: "/assets/images/about/title_promise03.svg",
              alt: "快適は、流れるように自然に",
            },
            text: (
              <>
                安全で安心できるステイはもちろん、流れるように自然に、いつでも快適にお客様にお過ごしいただきたい。チェックインからチェックアウトまで。その前もその後でさえも、私たちはそのための準備を怠りません。
              </>
            ),
            titleEn: "SEAMLESS STAY",
            textEn: (
              <>
                Attentive and fluid service ensures effortless travel
                experience, free of any extra worry or trouble. Our staffs are
                trained to listen from the heart and anticipate guests’ needs,
                embracing true Japanese Hospitality.
              </>
            ),
          },
          {
            title: {
              src: "/assets/images/about/title_promise04.svg",
              alt: "歓びは、細部に至るまで",
            },
            text: (
              <>
                細部への心配り、それはお客様がお気づきにならないかもしれない、小さな配慮の積み重ねです。それは、きっとお客様の心に残る歓びを、時には感動さえもお届けできる、そう私たちは考えています。
              </>
            ),
            titleEn: "SIMPLY DELIGHTFUL",
            textEn: (
              <>
                We care about all the small details that turn an ordinary visit
                into memorable moments. We weave genuine care and heartfelt
                gestures into every aspect of the experience, to deliver the
                simple delights that make a big difference.
              </>
            ),
          },
        ]}
      />
      <section className="l_sect u_bgGray">
        <LWrap>
          <CSectTitle
            title={{
              ja: <>沿革</>,
              en: "HISTORY",
            }}
          />
          <CHistoryList
            data={[
              {
                year: 1989,
                list: [
                  {
                    label: "6月1日",
                    text: <>ロイヤルパークホテル開業</>,
                  },
                  {
                    label: "7月1日",
                    text: <>三菱地所（株）ホテル事業部設立</>,
                  },
                ],
              },
              {
                year: 1993,
                list: [
                  {
                    label: "9月15日",
                    text: (
                      <>
                        横浜ロイヤルパークホテルニッコー開業（2001年
                        横浜ロイヤルパークホテルに名称変更）
                      </>
                    ),
                  },
                ],
              },
              {
                year: 1994,
                list: [
                  {
                    label: "10月1日",
                    text: (
                      <>
                        三菱地所ホテルグループからロイヤルパークホテルズへ、ホテルチェーン名称変更
                      </>
                    ),
                  },
                ],
              },
              {
                year: 1995,
                list: [
                  {
                    label: "4月8日",
                    text: <>仙台ロイヤルパークホテル開業</>,
                  },
                ],
              },
              {
                year: 2000,
                list: [
                  {
                    label: "11月1日",
                    text: (
                      <>
                        ホテル統括会社（株）ロイヤルパークホテルズアンドリゾーツ設立
                      </>
                    ),
                  },
                ],
              },
              {
                year: 2001,
                list: [
                  {
                    label: "4月1日",
                    text: (
                      <>（株）ロイヤルパークホテルズアンドリゾーツ営業開始</>
                    ),
                  },
                ],
              },
              {
                year: 2003,
                list: [
                  {
                    label: "7月1日",
                    text: (
                      <>
                        ロイヤルパーク汐留タワー開業（2020年 ザ
                        ロイヤルパークホテル アイコニック 東京汐留に名称変更）
                      </>
                    ),
                  },
                ],
              },
              {
                year: 2011,
                list: [
                  {
                    label: "7月30日",
                    text: (
                      <>
                        ロイヤルパークホテル ザ 福岡 開業（2018年 ザ
                        ロイヤルパークホテル 福岡 に名称変更）
                      </>
                    ),
                  },
                  {
                    label: "10月8日",
                    text: (
                      <>
                        ロイヤルパークホテル ザ 京都 開業（2018年 ザ
                        ロイヤルパークホテル 京都三条 に名称変更）
                      </>
                    ),
                  },
                ],
              },
              {
                year: 2013,
                list: [
                  {
                    label: "11月1日",
                    text: (
                      <>
                        ロイヤルパークホテル ザ 名古屋 開業（2018年 ザ
                        ロイヤルパーク キャンバス 名古屋 に名称変更）
                      </>
                    ),
                  },
                ],
              },
              {
                year: 2014,
                list: [
                  {
                    label: "9月30日",
                    text: (
                      <>
                        ロイヤルパークホテル ザ 羽田 開業（2018年 ザ
                        ロイヤルパークホテル 東京羽田 に名称変更）
                      </>
                    ),
                  },
                ],
              },
              {
                year: 2018,
                list: [
                  {
                    label: "4月13日",
                    text: <>ザ ロイヤルパークホテル 京都四条 開業（2024年9月 閉館）</>,
                  },
                  {
                    label: "10月5日",
                    text: <>ザ ロイヤルパークホテル 広島リバーサイド 開業</>,
                  },
                ],
              },
              {
                year: 2019,
                list: [
                  {
                    label: "3月20日",
                    text: <>ザ ロイヤルパーク キャンバス 銀座8 開業</>,
                  },
                  {
                    label: "6月7日",
                    text: <>ザ ロイヤルパーク キャンバス 大阪北浜 開業</>,
                  },
                ],
              },
              {
                year: 2020,
                list: [
                  {
                    label: "3月16日",
                    text: (
                      <>ザ ロイヤルパークホテル アイコニック 大阪御堂筋 開業</>
                    ),
                  },
                ],
              },
              {
                year: 2021,
                list: [
                  {
                    label: "1月21日",
                    text: <>ザ ロイヤルパーク キャンバス 神戸三宮 開業</>,
                  },
                  {
                    label: "3⽉12日",
                    text: <>ザ ロイヤルパークホテル 京都梅⼩路 開業</>,
                  },
                  {
                    label: "6⽉6日",
                    text: <>ザ ロイヤルパーク キャンバス 京都二条 開業</>,
                  },
                  {
                    label: "10⽉1日",
                    text: <>ザ ロイヤルパーク キャンバス 札幌大通公園 開業</>,
                  },
                ],
              },
              {
                year: 2022,
                list: [
                  {
                    label: "4⽉1日",
                    text: <>ザ ロイヤルパークホテル アイコニック 京都 開業</>,
                  },
                  {
                    label: "11⽉16日",
                    text: <>ザ ロイヤルパーク キャンバス 銀座コリドー 開業</>,
                  },
                ],
              },
              {
                year: 2023,
                list: [
                  {
                    label: "8⽉4日",
                    text: <>ザ ロイヤルパーク キャンバス 福岡中洲 開業</>,
                  },
                ],
              },
              {
                year: 2024,
                list: [
                  {
                    label: "1⽉1日",
                    text: <>（株）ロイヤルパークホテルズアンドリゾーツから三菱地所ホテルズ＆リゾーツ（株）へ社名変更</>,
                  },
                  {
                    label: "2⽉20日",
                    text: <>ザ ロイヤルパークホテル アイコニック 名古屋 開業</>,
                  },
                  {
                    label: "5⽉30日",
                    text: <>ザ ロイヤルパークホテル 銀座6丁目 開業</>,
                  },
                ],
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="l_sect u_bgWhite">
        <LWrap>
          <CSectTitle
            title={{
              ja: <>ブランド一覧</>,
              en: "BRAND",
            }}
          />
          <CBannercard
            data={[
              {
                img: {
                  src: "/assets/images/about/img_brand.png",
                  alt: "仙台ロイヤルパークホテル",
                },
                link: {
                  href: "https://www.srph.co.jp/",
                  blank: true,
                },
              },
              {
                img: {
                  src: "/assets/images/about/img_brand02.png",
                  alt: "ロイヤルパークホテル",
                },
                link: {
                  href: "https://www.rph.co.jp/",
                  blank: true,
                },
              },
              {
                img: {
                  src: "/assets/images/about/img_brand03.png",
                  alt: "横浜ロイヤルパークホテルロゴ",
                },
                link: {
                  href: "https://www.yrph.com/",
                  blank: true,
                },
              },
              {
                img: {
                  src: "/assets/images/about/img_brand04.png",
                  alt: "ICONIC",
                },
                link: {
                  href: "/brand/#iconic",
                },
              },
              {
                img: {
                  src: "/assets/images/about/img_brand05.png",
                  alt: "THE",
                },
                link: {
                  href: "/brand/#the",
                },
              },
              {
                img: {
                  src: "/assets/images/about/img_brand06.png",
                  alt: "CANVAS",
                },
                link: {
                  href: "/brand/#canvas",
                },
              },
              {
                img: {
                  src: "/assets/images/about/img_brand07.png",
                  alt: "MARUNOUCHI",
                },
                link: {
                  href: "https://www.marunouchi-hotel.co.jp/",
                  blank: true,
                },
              },
              {
                img: {
                  src: "/assets/images/about/img_brand08.png",
                  alt: "ATOLL",
                },
                link: {
                  href: "https://www.atollemerald.jp/",
                  blank: true,
                },
              },
            ]}
          />
          <CBtnList
            data={[
              {
                label: "ブランド一覧",
                color: "bgBlack",
                link: {
                  href: "/brand/",
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <NavAbout />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
